import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Post from "../components/post"
import Pagination from "../components/pagination"
import { globalPostsPerPages } from "../components/variables";

const PhotoGallery = () => {
  const postsPerPage = globalPostsPerPages;
  let numberOfPhotoPages;

  return (
    <Layout>
      <SEO/>
      <StaticQuery query={photoQuery} render={data => {
        numberOfPhotoPages = Math.ceil(data.allMarkdownRemark.totalCount / postsPerPage)
        console.log(numberOfPhotoPages);
        return (
          <div>
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <Post 
                preSlug="/photo-gallery/"
                slug={node.fields.slug} 
                title={node.frontmatter.title} 
                date={node.frontmatter.date}
                tags={node.frontmatter.tags}
              />
            ))}
            {data.allMarkdownRemark.totalCount > globalPostsPerPages ? 
              (<Pagination currentPage={1} numberOfPages={numberOfPhotoPages} postType={"photo-gallery"}/>) :
              (<></>)
            }
          </div>  
        )
      }} />
    </Layout>
  )
}

const photoQuery = graphql`
query photoQuery {
  allMarkdownRemark(
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {fileAbsolutePath: {regex: "/src/content/photo-gallery/"}, frontmatter: {postType: {eq: "photo-gallery"}}}
    limit: 15
  ) {
    totalCount
    edges {
      node {
        frontmatter {
          title
          date(formatString: "MMM Do YYYY")
          author
          postType
          tags
        }
        fields {
          slug
        }
      }
    }
  }
}
`

export default PhotoGallery